<template>
  <div class="settings-content-wrapper">
    <h1>Subscription</h1>
    <hr>
    <div v-if="isSubscribed">
      <b-message type="is-success">
        <strong>You are subscribed to Bunya!</strong>
      </b-message>
      <b-field class="modal-label" label="Plan">
        <b-input v-model="subscription.stripePlan.nickname" type="text" disabled/>
      </b-field>
      <b-field class="modal-label" label="Price">
        <b-input :value="this.$options.filters.currency(subscription.stripePlan.amount)" type="text" disabled/>
      </b-field>
      <br>
      <button :class="[{ 'is-loading': isLoading }, 'button', 'is-danger', 'is-outlined', 'is-fullwidth-mobile']"
              @click="cancelSubscription">Cancel Subscription
      </button>
    </div>
    <div v-if="canSubscribe">
      <b-message type="is-info" v-if="onTrail">
        <strong>Your free trail will expire {{ trialTimeToExpiry }} days from now. Enter you payment details to subscribe.</strong>
      </b-message>
      <b-message type="is-warning" v-else>
        <strong>Enter your payment details to continue using Bunya.</strong>
      </b-message>
      <b-field class="modal-label" label="Payment Details">
        <div ref="card"></div>
      </b-field>
    </div>
    <div v-if="canResume">
      <b-message type="is-warning" v-if="onTrail">
        <strong>You cancelled your subscription. You can continue to use Bunya until {{ trialTimeToExpiry }}.
          <br class="is-hidden-tablet-only">
          <br class="is-hidden-tablet-only">
          After that, you'll need to resume you subscription.</strong>
      </b-message>
    </div>
    <hr>
    <div class="level is-mobile">
      <div class="level-left"></div>
      <div class="level-right">
        <div class="level-item">
          <a class="button is-light" @click="cancel">Cancel</a>
        </div>
        <div class="level-item">
          <button :class="[{ 'is-loading': isLoading }, 'button', 'is-primary']" @click="subscribe" v-if="canSubscribe">
            Subscribe
          </button>
          <button :class="[{ 'is-loading': isLoading }, 'button', 'is-primary']"
                  @click="resumeSubscription" v-if="canResume">Resume Subscription
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>

  .is-fullwidth-mobile:not(:last-child) {
    margin-right: 10px;
  }

</style>

<script>

const stripePublishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
const stripe = window.Stripe(stripePublishableKey)

export default {
  name: 'Subscription',
  components: {},
  computed: {
    canSubscribe () {
      return !this.isSubscribed && !this.onGracePeriod
    },
    canResume () {
      return this.isCancelled && this.onGracePeriod
    },
    onTrail () {
      if (this.subscription) {
        return this.subscription.onTrail
      }
      return false
    },
    trialTimeToExpiry () {
      if (this.subscription) {
        return this.subscription.trialTimeToExpiry
      }
      return '14 days'
    },
    isSubscribed () {
      if (this.subscription) {
        return this.subscription.isSubscribed
      }
      return false
    },
    isCancelled () {
      if (this.subscription) {
        return this.subscription.isCancelled
      }
      return false
    },
    onGracePeriod () {
      if (this.subscription) {
        return this.subscription.onGracePeriod
      }
      return false
    },
    subscription () {
      return this.$store.getters['settings/subscription']
    }
  },
  data () {
    return {
      isLoading: false,
      card: null,
      token: null,
      stripeElementsStyle: {
        base: {
          color: '#2D3958',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#E9E9E9'
          }
        },
        invalid: {
          color: '#DD3300',
          iconColor: '#DD3300'
        }
      }
    }
  },
  methods: {
    cancel () {
      this.$router.push({ name: 'settings-home' })
    },
    subscribe () {
      this.isLoading = true
      const self = this
      stripe.createToken(this.card)
        .then((result) => {
          if (result.error) {
            this.isLoading = false
            self.hasCardErrors = true
            self.$forceUpdate()
            return
          }
          this.$store.dispatch('settings/saveSubscription', result.token.id)
            .then(() => {
              this.isLoading = false
              this.$store.dispatch('settings/fetchSubscription')
            })
            .catch(err => {
              this.isLoading = false
              this.$buefy.toast.open({
                message: err.response.data.message,
                type: 'is-danger',
                duration: 4000,
                queue: false
              })
            })
        })
    },
    resumeSubscription () {
      this.isLoading = true
      this.$store.dispatch('settings/resumeSubscription').then(() => {
        this.isLoading = false
      })
    },
    cancelSubscription () {
      this.isLoading = true
      this.$store.dispatch('settings/cancelSubscription').then(() => {
        this.isLoading = false
      })
    },
    createStripeElements () {
      if (this.canSubscribe) {
        this.$nextTick(() => {
          const elements = stripe.elements()
          this.card = elements.create('card', { style: this.stripeElementsStyle })
          this.card.mount(this.$refs.card)
        })
      }
    }
  },
  mounted: function () {
    this.isLoading = true
    this.$store.dispatch('settings/fetchSubscription')
      .then((response) => {
        this.isLoading = false
        if (!response.isSubscribed) {
          this.createStripeElements()
        }
      })
  }
}
</script>
